import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStripe } from '@stripe/react-stripe-js'
import DonationForm from '../DonationForm'
import { retrieveURL } from '@vidday/utils'
import axios from 'axios'
import config from '../../../../../../client/config.client'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import schemaAmount from '../DonationForm/schema'
import { formatToDecimalAmount } from '../../../../../api/checkout/utils'
import { useEventContext } from '../../../../contexts/EventProvider'
import { HStack, Alert, AlertIcon, Flex, Button, chakra, Text, LightMode, Link, Box } from '@chakra-ui/react'
import useStripeResponse from '../../../../hooks/useStripeResponse'
import StyledExternalLink from '../../../atoms/StyledExternalLink'
// import useBeginCheckoutEvent from '../../../../hooks/useBeginCheckoutEvent'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

export const defaultValues = { isCollecting: false, amount: 0, customAmount: 0, anonymous: false }

/**
 * Hook that retrieve the funders list and calculate the total of donation for an event
 * */
const useTotalDonations = () => {
	const funders = useSelector((s) => s.event.funders, shallowEqual)
	const [total, setTotal] = useState(0)

	useEffect(() => {
		let total = 0
		funders.forEach((el) => (total += el.amount))
		total = total / 100
		setTotal(total)
	}, [funders])

	return total
}

const DonationSelector = ({ disabled }) => {
	const { event, isOwner } = useEventContext()
	const eventId = event.id

	const total = useTotalDonations()

	const [isProcessing, setProcessing] = useState(false)

	const location = useLocation()
	const stripe = useStripe()
	const donateRef = useRef()

	/** Retrieve stripe statuses generated through search params */
	const stripeResponse = useStripeResponse(donateRef)

	/** Declare Form instance here and pass down to form. */
	const formInstance = useForm({
		mode: 'onChange',
		defaultValues: defaultValues,
		resolver: yupResolver(schemaAmount),
	})

	const { handleSubmit, errors, watch, setError: setFormError } = formInstance

	/** Watch changes in form values */
	const values = watch()

	/** Handler to proceed with the payment through stripe */
	const submitHandler = (data) => {
		/** if Stripe sdk is not loaded for some reason, force reload */
		if (!stripe && typeof window !== 'undefined' && window) {
			window.location.reload()
		}

		/** Check allowance */
		const allowance = config.tremendous.eventCap - total

		if (Number(data.customAmount) > allowance) {
			setFormError('customAmount', {
				message: `The maximum remaining allowance is ${formatToDecimalAmount(allowance * 100)} for this pot.`,
			})
		} else {
			/** Let user know we're processing */
			setProcessing(true)

			/** Compute final amount, in cents */
			let amount = 0
			if (data.customAmount) amount = Math.round(data.customAmount * 100) / 100
			else amount = data.amount
			amount = amount * 100

			/** POST Contribution and retrieve Stripe Checkout session ID */
			instance
				.post(`/v2/tremendous/contributions`, {
					amount: amount,
					eventId: eventId,
					successUrl: `${retrieveURL()}${location.pathname}?stripe_success=true`,
					cancelUrl: `${retrieveURL()}${location.pathname}?stripe_error=true`, // Redirect to the page that issued the request...
					anonymous: data.anonymous,
				})
				.then(function (response) {
					setProcessing(false)

					let body = response.data

					if (body.success && body.data.checkoutSessionId) {
						// push checkout info to gtm
						// useBeginCheckoutEvent(body.data.checkoutSessionId)
						// redirect to Stripe Checkout
						stripe.redirectToCheckout({ sessionId: body.data.checkoutSessionId }).then((result) => {
							// Handle when redirect fails
							if (result.error) {
								stripeResponse.setError(result.error.message)
							}
						})
					}
				})
				.catch(function (error) {
					setProcessing(false)
					if (error.response) {
						stripeResponse.setError(error.response.data.message)
					}
				})
		}
	}

	return (
		<HStack ref={donateRef} flexDirection="column" spacing="1rem">
			{stripeResponse.error && (
				<Alert status="error" borderRadius="base" mb="1rem">
					<AlertIcon />
					<Text color="dark">{stripeResponse.errorMessage}</Text>
				</Alert>
			)}

			{stripeResponse.success && (
				<Alert status="success" borderRadius="base" mb="1rem">
					<AlertIcon />
					<Text color="dark">{stripeResponse.successMessage}</Text>
				</Alert>
			)}

			<Flex justify="center" w="full">
				<chakra.form
					onSubmit={handleSubmit(submitHandler)}
					sx={{
						w: 'full',
						maxW: '614px',
						my: '0',
						mx: 'auto',
					}}>
					<DonationForm formInstance={formInstance} />

					<Flex justify="center" w="full" flexDirection="column" spacing="1rem">
						{values.amount != 0 && (
							<LightMode>
								<Button
									type="submit"
									mt="1rem"
									size="lg"
									disabled={Object.keys(errors).length > 0 || isProcessing || disabled}>
									Continue to Payment
								</Button>
								{!isOwner && <StyledExternalLink />}
							</LightMode>
						)}
					</Flex>
				</chakra.form>
			</Flex>
		</HStack>
	)
}

export default DonationSelector
